.heroIMAGE {
  width: 100%;
  position: relative;
  height: 100vh;
  overflow: hidden;
  background-image: url("https://github.com/jonathanschimpf/React-Portfolio/blob/main/public/images/jon_berks_crop.jpg?raw=true");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

/* Remove parallax for mobile devices */
@media (max-width: 768px) {
  .heroIMAGE {
    background-attachment: scroll;
  }
}

/* Specific styles for iPads */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  .heroIMAGE {
    background-size: auto 100vh; /* Adjusts the background image to fill the height */
    background-position: center center; /* Centers the image */
    background-attachment: scroll; /* Disable the fixed attachment */
  }
}

/* Adjustments for devices with a height of 1024px which includes some iPads in landscape */
@media only screen and (min-device-height : 1024px) and (max-device-height : 1024px) {
  .heroIMAGE {
    background-size: auto 100vh; /* Adjusts the background image to fill the height */
    background-position: center center; /* Centers the image */
    background-attachment: scroll; /* Disable the fixed attachment */
  }
}