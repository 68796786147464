/* The WHOLE component damnit */

.projectsEverything {
  margin-top: 20vh;
 margin-bottom: 35vh;
}

/* Main Container */
.projectsContainer {
  padding-top: 10vh;
}

/* Project Title Styling */
.projectsTitleContainer {
  padding-top: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: #000000;
}

/* Cards Styles */
.card {
  margin-right: 1rem;
  padding-bottom: 1rem;
  margin-top: 1rem;
  border: none;
  background-color: transparent;
}

.card-body {
  text-align: center;
}

/* Remove the gross ass
focus outline from buttons + links */
.btn:focus,
.card a:focus {
  outline: none;
  box-shadow: none;
}

.btn:focus,
.card a:focus,
.close:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.5); /* A softer white outline */
}

/* Remove focus outline and box shadow from all focusable elements */
*:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* Specifically target the close button in the modal header */
.modal-header .close:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* Update the button's :active state */
.btn:active,
.btn.active {
  background-color: rgb(255, 255, 255);
  border-color: rgb(255, 255, 255);
}

/* Update the button's :focus state */
.btn:focus,
.btn.focus {
  background-color: rgb(255, 255, 255);
  border-color: rgb(255, 255, 255);
}

/* For the modal close button if it still shows an outline or box shadow */
.modal-header .close:active,
.modal-header .close.focus,
.modal-header .close:focus {
  outline: none !important;
  box-shadow: none !important;
}

button.btn-info,
.project-links button.btn-info:hover,
.project-links button.btn-info:focus,
.project-links button.btn-info:active {
  color: rgb(167, 167, 167);
  background-color: transparent;
  border-color: transparent;
}

/* NAW BOOTSTRAP */
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: rgb(60, 60, 60) !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

/* Link Styles */

/* Center project links under the card title */

.project-links {
  display: flex;
  justify-content: center;
  align-items: center;
}
.project-links a,
.project-links button {
  margin-right: 0.5rem;
  color: #000;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  font-size: 11px;
}

.project-links a:hover,
.project-links button:hover {
  color: rgb(167, 167, 167);
}

/* Card Titles */
.card-title a {
  font-size: 12px;
  font-weight: 600;
  color: #000;
  text-decoration: none;
}

.card-title a:hover {
  color: rgb(167, 167, 167);
}

/* Image Styles */
.cardImg {
  display: block;
  margin: auto;
  max-width: 250px;
  max-height: 250px;
  transition: transform 0.3s ease-out;
  transition-duration: 4s; /* vendorless fallback */
  -o-transition-duration: 4s; /* opera */
  -ms-transition-duration: 4s; /* IE 10 */
  -moz-transition-duration: 4s; /* Firefox */
  -webkit-duration: 4s; /*safari and chrome */
}


.cardImg:hover {
  transform: scale(1.05);
  transition-duration: 4s;
  transition-delay: 0.2s;
}
