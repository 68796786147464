.footer-container {
  width: 100%;
  padding: 1rem 0;
  overflow-x: auto;
}

.icon-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.icon-col {
  padding: 0.5rem;
  flex: 0 0 auto;
}

.responsiveIcon {
  display: inline-block;
  margin: 0 5px;
  color: inherit;
  text-decoration: none;
  flex: none;
}

.responsiveIcon:hover {
  opacity: 0.7;
}


.tooltipFooter .tooltip-inner {
  background-color: transparent !important; 
  color:rgb(150, 150, 150) !important;
  font-weight: bold;
}


/* Remove the tooltip arrow entirely */
.tooltipFooter .tooltip .arrow::before,
.tooltipFooter .tooltip .arrow::after {
  content: none !important; 
}

/* If you want to change the arrow color instead of removing */
.tooltipFooter .tooltip .arrow::before {
  border-bottom-color: transparent !important; 
}

 /* TOOLTIP ARROW DELETE */
.tooltipFooter .tooltip-arrow,
.tooltipFooter .arrow {
  display: none !important;
}




/* RESPONSIVE SOCIALS AND RESUME */

@media (max-width: 1050px) {
  .responsiveIcon {
    margin: -2.5px;
    font-size: 98.25%;
  }
  .divfooter {
    padding: -1.5px;
  }
}


@media (max-width: 950px) {
  .responsiveIcon {
    /* Smaller font-size as per your design */
    font-size: 90%;
    /* Adjust padding and margin if necessary */
    padding: 0.5em;
  }
}

@media (max-width: 650px) {
  .responsiveIcon {
    /* Even smaller font-size for very small devices */
    font-size: 80%;
    /* Adjust padding and margin to keep the layout tidy */
    padding: 0.25em;
  }
}
