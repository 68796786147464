.hello-background {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: black;
  color: white;
  padding: 4em 1em;
  text-align: left;
}

.hello-content {
  max-width: 700px;
  margin-top: 30vh;
  margin-bottom: 35vh;
}

.hello-title {
  color: red;
}

.hello-paragraph {
  font-size: 1.25rem;
  line-height: 1.6;
  color: grey;
}

/* Responsive font sizes */
@media (max-width: 575px) {
  .hello-title {
    font-size: 2rem;
  }

  .hello-paragraph {
    font-size: 1rem;
  }
}