.button,
.button:hover,
.button:active,
.button:visited,
.button:focus {
  text-decoration: none;
}

a,
a:hover,
a:active,
a:visited,
a:focus {
  text-decoration: none;
}

h2 {
  color: white;
}

.interestsIcon {
  color: white;
}

.interestsIcon:hover {
  color: rgb(189, 0, 0);
  transition: all 0.25s;
}

.interestsTransform:hover .interestsTransform:focus .interestsTransform:active {
  transform: translateY(-4x);
}

.interestsBox {
  min-height: auto;
  width: 100%;
  height: 100%;
  padding-top: 20vh !important;
  padding-bottom: 40vh !important;
  background: black;
}

.deviContainer {
  max-width: 800px;
}

.interestsTitle {
  margin-bottom: 3em;
  font-size: 24px;
}

.vertical-center {
  vertical-align: middle ;
  align-items: center;
}

.tooltip-inner {
  color: rgb(189, 0, 0)!important;
  font-weight: bold !important;
}


/* media queries */

@media (max-width: 895px) {
  .deviContainer.container {
    max-width: 878px;
  }
}

@media (max-width: 767px) {
  .deviContainer.container {
    max-width: 750px;
  }
}

@media (max-width: 639px) {
  .deviContainer.container {
    max-width: 622px;
  }
}

@media (max-width: 519px) {
  .deviContainer.container {
    max-width: 502px;
  }
}
@media (max-width: 383px) {
  .deviContainer.container {
    max-width: 366px;
  }
}

@media (max-width: 263px) {
  .deviContainer.container {
    max-width: 246px;
  }
}
