.navbar,
nav {
  padding-top: 4px;
  padding-bottom: 4.8px;
  background: transparent;
  background-color: transparent;
  width: 100%;
}

.navbar-toggler {
  border: none;
}

.navbar-collapse {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
/* mobile or narrow viewports  */
.navbar-collapse .nav-link {
  padding-right: 0.75rem; 
}
.navbar-dark .navbar-nav .nav-link {
  cursor: pointer; 
}

.collapse.navbar-collapse {
  width: 100%;
}

.navbar-brand {
  font-size: 16.5px;
  padding-bottom: 3px;
  padding-left: 0.5em;
  display: flex;
  justify-content: flex-start;
  margin-left: 0 !important;
  cursor: pointer !important;
}

#brandOverride {
  margin-left: 0 !important;
}


.greyFont {
  color: rgb(167, 167, 167);
}

.whiteFont {
  color: white;
}

.nav-item {
  color: rgb(167, 167, 167);
  margin-left: auto;
}
