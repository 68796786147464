* {
  font-family: Futura, "Trebuchet MS", Arial, sans-serif;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  outline: none;
  overflow: visible !important;
  /* \/ FOR TESTING \/ */
  /* border: 1px solid red; */
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

/* Top Specificity */

.row > * {
  width: auto;
  box-sizing: border-box;
}

.card-deck > * {
  margin-top: 10px;
  margin-left: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 0.5rem;
}