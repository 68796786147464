.button,
.button:hover,
.button:active,
.button:visited,
.button:focus,
a,
a:hover,
a:active,
a:visited,
a:focus {
    text-decoration: none;
}

.fa:hover,
a:hover {
    color: rgb(167, 167, 167);
}

/* Never stop waving :) */

.wave {
  animation-name: wave-animation;  
  animation-duration: 2.5s;       
  animation-iteration-count: infinite; 
  transform-origin: 70% 70%;      
  display: inline-block;
  filter: brightness(90%);
}

.space {
  padding-bottom:15vh;
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
   10% { transform: rotate(15.0deg) }  
   20% { transform: rotate(-8.0deg) }
   30% { transform: rotate(15.0deg) }
   40% { transform: rotate(-5.0deg) }
   50% { transform: rotate(10.0deg) }
   60% { transform: rotate( 0.0deg) }  
  100% { transform: rotate( 0.0deg) }
}

.fontSizeAdjust {
    font-size: 34px;
}

.blackoutLink,
a {
    color: black;
}
.contactSpacing {
  padding-top: 2.5em;
  padding-bottom: 1em;
}

.connectContactPadding {
  padding-bottom: 15vh;
  padding-top: 15vh;
}

.centeredContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  padding-top: 10vh;
  margin-top: 25vh;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  flex-wrap: wrap; /* Allow items to wrap on small screens */
}

.iconRow {
  margin-top: 5em;   
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  gap: 1.5em;
}

.contactTitleWidth {
  max-width: 540px;
}


.tooltipContactFooter .tooltip-inner {
  background-color: transparent !important; 
  color:rgb(150, 150, 150) !important;
  font-weight: bold;
}



.tooltipContactFooter.tooltip .arrow::before,
.tooltipContactFooter .tooltip .arrow::after {
  content: none !important; 
}


.tooltipContactFooter .tooltip .arrow::before {
  border-bottom-color: transparent !important; 
}

.tooltip .arrow::before, .tooltip .arrow::after {
  display: none !important;
}

.tooltip .arrow::before {
  border-top-color: transparent !important; 
}
 /* TOOLTIP ARROW DELETE */
.tooltipFooter .tooltip-arrow,
.tooltipFooter .arrow {
  display: none !important;
}

.tooltipContactFooter .tooltip-arrow,
.tooltipContactFooter .arrow {
  display: none !important;
}


/* RESPONSIVE SOCIALS AND RESUME */

@media (max-width: 1050px) {
  .responsiveIcon {
    margin: -2.5px;

  }
  .divfooter {
    padding: -1.5px;
  }
}


@media (max-width: 950px) {
  .responsiveIcon {
    /* Smaller font-size as per your design */
    font-size: 90%;
    /* Adjust padding and margin if necessary */
    padding: 0.5em;
  }
}

@media (max-width: 650px) {
  .responsiveIcon {
    /* Even smaller font-size for very small devices */
    font-size: 80%;
    /* Adjust padding and margin to keep the layout tidy */
    padding: 0.25em;
  }
}
