/* MESSAGE */
.centerHeader {
  margin-top: 2.5px;
}

.containerHeader {
  min-height: 100vh;
  width:100%;
  background: white;
} 

.centerHeader {
  display: flex;
  text-align: center;
  align-content: center;
}

.messageMargins {
  margin: auto;
  padding-top:auto;
  padding-bottom: auto;
  vertical-align: middle;
}

.blacked {
  color: black;
  font-weight: 700;
  padding-bottom: 40px;
}

.greyed {
  color:rgb(104, 104, 104);
}

/* MEDIA QUERIES FOR SMALLER SCREEN WIDTHS */
@media screen and (max-width: 440px) {
  h1.responsiveH1Font {
    font-size: 8vw;
  }
}

@media screen and (max-width: 440px) {
  h2.responsiveH2Font {
    font-size: 8vw;
  }
}